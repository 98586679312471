import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Partners</h2>
          <p>
          At Enteranet LLC, collaboration is at the heart of our success. We take pride in fostering strong partnerships with industry leaders and innovators to deliver unparalleled IT solutions. Together with our esteemed partners, we embark on a journey of mutual growth, leveraging collective expertise to navigate the complexities of the digital landscape.
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="image-container">
                    {" "}
                    <img src={d.img} alt="..." className="partner-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
