import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
          IT services encompass a range of solutions supporting and optimizing technology resources within organizations. These services, including network management, cybersecurity, and software development, aim to enhance productivity and ensure efficient, secure digital operations. In today's dynamic business environment, effective IT services are essential for competitiveness and resilience.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <FontAwesomeIcon icon={d.icon} />
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
